import React from "react"
import { Grid, Box, Text, Image, Divider } from "theme-ui"
import Layout from "../../components/layout"
import SEO from "../../components/seo"
import Breadcrumbs from "../../components/Breadcrumbs"
import LinkCard from "../../components/LinkCard"

import theConversationImage from "../../images/spots/the-conversation.png"
import windgeistImage from "../../images/spots/windgeist.png"
import brellImage from "../../images/spots/brell.png"
import discoveryImage from "../../images/spots/discovery.png"

const AboutPage = () => (
  <Layout>
    <SEO title="About Quest" />

    <Breadcrumbs title="About" headline="Your greatest adventure begins here" sx={{ pr: [0, 0, 8] }} />

    <Grid variant="textWrap">
      <Box>
        <Text as="p" variant="bodyLarge">Quest is a fantasy roleplaying game about the adventures of you and your friends. Together, you’ll create amazing characters and tell their stories. The game helps you tell an exciting story in a world of magic and danger.</Text>
        <Text as="p" variant="bodyLarge">The rules of the game help you create a story that’s totally unique. No two games are the same, and the possibilities are endless.</Text>
      </Box>
    </Grid>

    <Grid variant="base" columns={[1, 2]} gap={[4, 6, 8]} sx={{ alignItems: "center", my: [7, 8], bg: "grayXLight", overflowX: "visible" }}>
      <Image src={brellImage} alt="The Brell" sx={{ mt: [6, -8], mb: [0, -8], width: "100%", maxWidth: [400, "100%"], mx: "auto" }} />
      
      <Box sx={{ py: [5, 6] }}>
        <Text as="h2" variant="title02">Easy and fun to learn</Text>
        <Text as="p" variant="bodyLarge">Quest uses rules that are relaxed and flexible, allowing you to tell your story without complicated restrictions. There are only a few things you need to learn to get started, and reading the basics takes most people around 20 minutes.</Text>
      </Box>
    </Grid>

    <Grid variant="base" columns={[1, 2]} gap={[4, 6, 8]} sx={{ alignItems: "center", my: [7, 8] }}>
      <Box sx={{ py: [5, 6] }}>
        <Text as="h2" variant="title02">Evocative roles</Text>
        <Text as="p" variant="bodyLarge">You’ll create any kind of character you want, and then choose from one of eight exciting roles. Each role has distinct and provocative abilities that will make your character shine in the story, like transforming into animals, summoning storms, or traveling to the stars.</Text>
      </Box>
      
      <Image src={windgeistImage} alt="Windgeist" sx={{ mt: [-6, 0], mb: [0, 0], width: "100%", maxWidth: [400, "100%"], mx: "auto" }} />
    </Grid>

    <Grid variant="base" columns={[1, 2]} gap={[4, 6, 8]} sx={{ alignItems: "center", my: [7, 8], bg: "primary10", overflowX: "visible" }}>
      <Image src={theConversationImage} alt="The Conversation" sx={{ mt: [6, -8], mb: [0, -8], width: "100%", maxWidth: [400, "100%"], mx: "auto" }} />
      
      <Box sx={{ py: [5, 6] }}>
        <Text as="h2" variant="title02">Inclusive & approachable</Text>
        <Text as="p" variant="bodyLarge">Quest breaks from the rigid traditions of other roleplaying games. We’ll help you create a character that sounds fun and interesting to you, without putting restrictions on your identity or imagination. Everyone is welcome at the table.</Text>
      </Box>
    </Grid>

    <Grid variant="base" columns={[1, "3fr 2fr"]} gap={[4, 6, 8]} sx={{ alignItems: "center", mt: [7, 8], mb: [-6, -8] }}>
      <Box sx={{ py: [5, 6] }}>
        <Text as="h2" variant="title02">Breathtaking products</Text>
        <Text as="p" variant="bodyLarge">Quest is thoughtfully designed and made with beautiful materials. From our books to our card decks, our players rave about the quality of the game. It's something you'll want to leave on your table even when you're finished playing.</Text>
      </Box>
      
      <Image src={discoveryImage} alt="Discovery" sx={{ mt: [-6, 0], width: "100%", maxWidth: [300, "70%"], mx: "auto" }} />
    </Grid>

    <Grid variant="base">
      <Divider />
    </Grid>

    <Grid variant="textWrap">
      <Text as="p" variant="bodyLarge">Ready to learn more?</Text>
    </Grid>

    <Grid variant="base" columns={[1, 2]} sx={{ gridGap: 6 }}>
      <LinkCard title="Rules" url="/rules" buttonLabel="Learn to Play" />
      <LinkCard title="Roles" url="/roles" buttonLabel="See the Roles" />
    </Grid>
  </Layout>
)

export default AboutPage